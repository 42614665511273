import axios from 'axios';
import moment from 'moment';
import { toast } from 'react-toastify';
import {
  GET_ALL_START,
  GET_ALL_SUCCESS,
  GET_ALL_ERROR,
  GET_SINGLE_START,
  GET_SINGLE_SUCCESS,
  GET_SINGLE_ERROR,
  UPLOAD_IMAGE_START,
  UPLOAD_IMAGE_SUCCESS,
  UPLOAD_IMAGE_ERROR,
  ADD_START,
  ADD_SUCCESS,
  ADD_ERROR,
  DELETE_START,
  DELETE_SUCCESS,
  DELETE_ERROR,
  UPDATE_START,
  UPDATE_SUCCESS,
  EXPORT_START,
  EXPORT_SUCCESS,
  EXPORT_ERROR,
  UPDATE_ERROR,
} from './types';
import HttpService from '../../../services/Http';
import ApiErrorHandler from '../../../utils/ApiErrorHandler';
import configUrl from '../../../config/config';
import StoreService from '../../../services/Store';

export const getProducts = (page = 1, limit = 10) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_START });

    const res = await HttpService.get(`/product/plant-view-products/all?page=${page}&limit=${limit}`, { status: 'waiting' });

    dispatch({
      type: GET_ALL_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const getProduct = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_SINGLE_START });

    const res = await HttpService.get(`/product/plant-view-product-details/${id}`);

    dispatch({
      type: GET_SINGLE_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_SINGLE_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const uploadImages = (data) => async (dispatch) => {
  try {
    dispatch({ type: UPLOAD_IMAGE_START });

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    const res = await HttpService.post(
      '/admin/upload-images',
      data,
      config,
    );

    dispatch({
      type: UPLOAD_IMAGE_SUCCESS,
      payload: res.data,
    });
    toast.success('Images uploaded successfully');
  } catch (error) {
    dispatch({
      type: UPLOAD_IMAGE_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const addProduct = (data) => async (dispatch) => {
  try {
    dispatch({ type: ADD_START });

    const res = await HttpService.post(
      '/admin/create-product',
      data,
    );

    dispatch({
      type: ADD_SUCCESS,
      payload: res.data,
    });
    toast.success('Product added successfully');
  } catch (error) {
    dispatch({
      type: ADD_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const getAdminProducts = (page = 1, limit = 12) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_START });

    const res = await HttpService.get(`/product/admin-view-products/all?page=${page}&limit=${limit}`, { status: 'waiting' });

    dispatch({
      type: GET_ALL_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const getAdminProduct = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_SINGLE_START });

    const res = await HttpService.get(`/product/admin-view-product-details/${id}`);

    dispatch({
      type: GET_SINGLE_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_SINGLE_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const upadateProduct = (id, data) => async (dispatch) => {
  try {
    dispatch({ type: ADD_START });

    const res = await HttpService.patch(
      `/admin/edit-product/${id}`,
      data,
    );

    dispatch({
      type: ADD_SUCCESS,
      payload: res.data,
    });
    toast.success('Product updated successfully');
  } catch (error) {
    dispatch({
      type: ADD_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const deleteProduct = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_START });

    const res = await HttpService.delete(
      `/product/admin-delete-product/${id}`,
    );

    dispatch({
      type: DELETE_SUCCESS,
      payload: res.data,
    });
    toast.success('Product deleted successfully');
  } catch (error) {
    dispatch({
      type: DELETE_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const getAllProductsList = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_START });
    const baseUrl = '/hflhnl/get-hfl-indents';
    const params = new URLSearchParams();
    if (values?.page) params.append('page', values?.page);
    if (values?.limit) params.append('limit', values?.limit);
    if (values?.prodName) params.append('prodName', values?.prodName);
    if (values?.plantIcStatus) params.append('plantIcStatus', values?.plantIcStatus);
    const string = `${baseUrl}${params.toString() ? `?${params.toString()}` : ''}`;
    const res = await HttpService.get(`${string}`);

    dispatch({
      type: GET_ALL_SUCCESS,
      payload: res?.data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const getAllRegionalHeadIndentsApprovals = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_START });
    const baseUrl = '/hflhnl/hfl-indents';
    const params = new URLSearchParams();
    if (values?.page) params.append('page', values?.page);
    if (values?.limit) params.append('limit', values?.limit);
    if (values?.prodName) params.append('prodName', values?.prodName);
    if (values?.rHStatus) params.append('rHStatus', values?.rHStatus);
    if (values?.hnlStatus) params.append('hnlStatus', values?.hnlStatus);
    if (values?.status) params.append('status', values?.status);
    if (values?.plantCode) params.append('plantCode', values?.plantCode);
    if (values?.materialCode) params.append('materialCode', values?.materialCode);
    if (values?.feedType) params.append('feedType', values?.feedType);
    const string = `${baseUrl}${params.toString() ? `?${params.toString()}` : ''}`;
    const res = await HttpService.get(`${string}`);

    dispatch({
      type: GET_ALL_SUCCESS,
      payload: res?.data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
  }
};

export const updatePlantSideHnlRequests = (data, callBack) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_START });
    const response = await HttpService.put(
      '/hflhnl/update-indent',
      data,
    );
    dispatch({
      type: UPDATE_SUCCESS,
      payload: response?.data,
    });
    if (callBack) {
      callBack(response);
    }
  } catch (error) {
    dispatch({
      type: UPDATE_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
  }
};

export const updateRegionalHeadHnlRequests = (data, callBack) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_START });
    const response = await HttpService.put(
      '/hflhnl/rh-update-indent',
      data,
    );
    dispatch({
      type: UPDATE_SUCCESS,
      payload: response?.data,
    });
    if (callBack) {
      callBack(response);
    }
  } catch (error) {
    dispatch({
      type: UPDATE_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
  }
};

export const updateHnlIndentRequests = (data, callBack) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_START });
    const response = await HttpService.put(
      '/hflhnl/hnl-update-indent',
      data,
    );
    dispatch({
      type: UPDATE_SUCCESS,
      payload: response?.data,
    });
    if (callBack) {
      callBack(response);
    }
  } catch (error) {
    dispatch({
      type: UPDATE_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
  }
};

export const exporRegionalHeadHnlIndentingApproval = (values) => async (dispatch) => {
  try {
    dispatch({ type: EXPORT_START });
    const baseUrl = '/hflhnl/export-indents';
    const params = new URLSearchParams();
    if (values?.status) {
      params.append('status', values?.status);
    }
    if (values?.plantCode) {
      params.append('plantCode', values?.plantCode);
    }
    if (values?.materialCode) {
      params.append('materialCode', values?.materialCode);
    }
    if (values?.feedType) {
      params.append('feedType', values?.feedType);
    }
    const res = await axios({
      url: `${configUrl.apiUrl}${baseUrl}?${params.toString()}`,
      method: 'GET',
      data: { status: 'finished' },
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With',
        Accept: 'application/json',
        session: StoreService.getToken(),
      },
    });
    const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${localStorage.getItem('userName')}-HNL-INDENTS-${moment().format('YYYY-MM-DD')}.xlsx`);
    document.body.appendChild(link);
    link.click();
    dispatch({
      type: EXPORT_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: EXPORT_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
  }
};

export const getPlantAndMaterialCodes = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_SINGLE_START });

    const baseUrl = '/hflhnl/plantCode-materialCodes';
    const params = new URLSearchParams();
    if (values?.rHStatus) params.append('rHStatus', values?.rHStatus);
    if (values?.hnlStatus) params.append('hnlStatus', values?.hnlStatus);
    const string = `${baseUrl}${params.toString() ? `?${params.toString()}` : ''}`;
    const res = await HttpService.get(`${string}`);

    dispatch({
      type: GET_SINGLE_SUCCESS,
      payload: res.data,
    });

    return res.data;
  } catch (error) {
    dispatch({
      type: GET_SINGLE_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
    throw error;
  }
};

export const getFilterDropDownData = () => async (dispatch) => {
  try {
    dispatch({ type: GET_SINGLE_START });

    const baseUrl = '/hflhnl/HFL-admin-filter-dropdown';
    const res = await HttpService.get(baseUrl);

    dispatch({
      type: GET_SINGLE_SUCCESS,
      payload: res.data,
    });

    return res.data;
  } catch (error) {
    dispatch({
      type: GET_SINGLE_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
    throw error;
  }
};

export const getSummaryReport = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_START });
    const baseUrl = '/hflhnl/material-quantities';
    const params = new URLSearchParams();
    if (values?.regionName) params.append('regionName', values?.regionName);
    if (values?.plantName) params.append('plantName', values?.plantName);
    if (values?.productCategory) params.append('productCategory', values?.productCategory);
    if (values?.productName) params.append('productName', values?.productName);
    if (values?.startDate) params.append('startDate', values?.startDate);
    if (values?.endDate) params.append('endDate', values?.endDate);
    const string = `${baseUrl}${params.toString() ? `?${params.toString()}` : ''}`;
    const res = await HttpService.get(`${string}`);
    dispatch({
      type: GET_ALL_SUCCESS,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: GET_ALL_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
    throw error;
  }
};

export const getRegionWiseData = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_START });
    const baseUrl = '/hflhnl/get-regionWise-data';
    const params = new URLSearchParams();
    if (values?.plant) params.append('plant', values?.plant);
    const string = `${baseUrl}${params.toString() ? `?${params.toString()}` : ''}`;
    const res = await HttpService.get(`${string}`);
    dispatch({
      type: GET_ALL_SUCCESS,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: GET_ALL_ERROR,
      payload: ApiErrorHandler.selectMessage(error),
    });
    toast.error(ApiErrorHandler.selectMessage(error));
    throw error;
  }
};
