import React, { useEffect, useState } from 'react';
import './DetailedReportForAdmin.scss';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft, faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';
import { getRegionWiseData } from '../../../store/modules/product/actions';

const HnlIndentingDetailedReports = () => {
  const dispatch = useDispatch();
  const [showPlantWise, setShowPlantWise] = useState(false);
  const [regionData, setRegionData] = useState([]);
  const [data, setData] = useState([]);

  const getReports = async (values = {}) => {
    try {
      const response = await dispatch(getRegionWiseData(values));
      if (response) {
        if (showPlantWise) setData(response);
        else setRegionData(response);
      }
    } catch (error) {
      console.error('Error fetching Summary Report:', error);
    }
  };
  useEffect(() => {
    if (showPlantWise) {
      getReports({ plant: 'plant' });
    } else {
      getReports();
    }
  }, [showPlantWise]);

  const sortedData = data.sort((a, b) => {
    // Compare regionName
    if (a.regionName < b.regionName) return -1;
    if (a.regionName > b.regionName) return 1;

    // If regionName is the same, compare plantName
    if (a.plantName < b.plantName) return -1;
    if (a.plantName > b.plantName) return 1;

    // If plantName is also the same, compare materialName
    if (a.materialName < b.materialName) return -1;
    if (a.materialName > b.materialName) return 1;

    return 0;
  });

  const sortedRegionData = regionData.sort((a, b) => {
    if (a.regionName < b.regionName) return -1;
    if (a.regionName > b.regionName) return 1;

    return 0;
  });

  return (
    <div>
      <div className="detailed-reports">
        <div className="table-container">
          <div className="row">
            <div className="col-12">
              { showPlantWise ? (
                <table className="table table-bordered">
                  <thead style={{
                    position: 'sticky',
                    top: '0',
                    backgroundColor: '#f1f1f1',
                  }}
                  >
                    <tr>
                      <th rowSpan={2}>Region Wise</th>
                      <th rowSpan={2}>Plant Wise </th>
                      <th rowSpan={2}>Material Wise </th>
                      <th colSpan={3}>Feed </th>
                      <th colSpan={3}>Supplement </th>
                      <th colSpan={3}>Pharmacy </th>
                    </tr>
                    <tr>
                      <th>Order Quantity </th>
                      <th>Fullfilled Quantity </th>
                      <th>Fullfilled % </th>
                      <th>Order Quantity </th>
                      <th>Fullfilled Quantity </th>
                      <th>Fullfilled % </th>
                      <th>Order Quantity </th>
                      <th>Fullfilled Quantity </th>
                      <th>Fullfilled % </th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortedData.map((e, i) => (
                      <tr>
                        {(i !== 0 && e.regionName === data[i - 1].regionName) ? <th className="no-border" /> : (
                          <th className="only-top-border" onClick={() => setShowPlantWise(false)} onHove>
                            <>
                              {e.regionName}
                              {' '}
                              <FontAwesomeIcon icon={faArrowAltCircleLeft} size="lg" />
                              {' '}

                            </>
                          </th>
                        )}
                        {(i !== 0 && e.plantName === data[i - 1].plantName) ? <th className="no-border" /> : <th className="only-top-border">{e.plantName}</th>}
                        {(i !== 0 && e.materialName === data[i - 1].materialName) ? <th className="no-border" /> : <th className="only-top-border">{e.materialName}</th>}
                        {e.feedType === 'Feed'
                          ? (
                            <>
                              <th>{Math.round(e.totalRhApprovedQuantity * 100) / 100}</th>
                              <th>{Math.round(e.fullFilledQuantity * 100) / 100}</th>
                              <th>{Math.round(e.fullFilledPercentage * 100) / 100}</th>
                            </>
                          ) : (
                            <>
                              <th />
                              <th />
                              <th />
                            </>
                          )}
                        {e.feedType === 'Feed supplements'
                          ? (
                            <>
                              <th>{Math.round(e.totalRhApprovedQuantity * 100) / 100}</th>
                              <th>{Math.round(e.fullFilledQuantity * 100) / 100}</th>
                              <th>{Math.round(e.fullFilledPercentage * 100) / 100}</th>
                            </>
                          ) : (
                            <>
                              <th />
                              <th />
                              <th />
                            </>
                          )}
                        {e.feedType === 'Pharmacy'
                          ? (
                            <>
                              <th>{Math.round(e.totalRhApprovedQuantity * 100) / 100}</th>
                              <th>{Math.round(e.fullFilledQuantity * 100) / 100}</th>
                              <th>{Math.round(e.fullFilledPercentage * 100) / 100}</th>
                            </>
                          ) : (
                            <>
                              <th />
                              <th />
                              <th />
                            </>
                          )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <>
                  <table className="table table-bordered">
                    <thead style={{
                      position: 'sticky',
                      top: '0',
                      backgroundColor: '#f1f1f1',
                    }}
                    >
                      <tr>
                        <th rowSpan={2}>Region Wise </th>
                        <th colSpan={3}>Feed </th>
                        <th colSpan={3}>Supplement </th>
                        <th colSpan={3}>Pharmacy </th>
                      </tr>
                      <tr>
                        <th>Order Quantity </th>
                        <th>Fullfilled Quantity </th>
                        <th>Fullfilled % </th>
                        <th>Order Quantity </th>
                        <th>Fullfilled Quantity </th>
                        <th>Fullfilled % </th>
                        <th>Order Quantity </th>
                        <th>Fullfilled Quantity </th>
                        <th>Fullfilled % </th>
                      </tr>
                    </thead>
                    <tbody>
                      {sortedRegionData.map((e) => (
                        <tr>
                          <th className="only-top-border" onClick={() => setShowPlantWise(true)} onHove>
                            <>
                              {e.regionName}
                              {' '}
                              <FontAwesomeIcon icon={faArrowAltCircleRight} size="lg" />
                              {' '}

                            </>
                          </th>
                          <th>{Math.round(e.feedQty * 100) / 100}</th>
                          <th>{Math.round(e.feedFullFillQty * 100) / 100}</th>
                          <th>{Math.round(e.feefFullFillPer * 100) / 100}</th>
                          <th>{Math.round(e.suppQty * 100) / 100}</th>
                          <th>{Math.round(e.suppFullFillQty * 100) / 100}</th>
                          <th>{Math.round(e.suppFullFillPer * 100) / 100}</th>
                          <th>{Math.round(e.pharmacyQty * 100) / 100}</th>
                          <th>{Math.round(e.pharmacyFullFillQty * 100) / 100}</th>
                          <th>{Math.round(e.pharmacyFullFillPer * 100) / 100}</th>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HnlIndentingDetailedReports;
